// Generated by Framer (90417e1)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-rZDRr"

const variantClassNames = {FJQ9Yvmmd: "framer-v-1ri2jjr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "FJQ9Yvmmd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ri2jjr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FJQ9Yvmmd"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rZDRr.framer-1wwglsw, .framer-rZDRr .framer-1wwglsw { display: block; }", ".framer-rZDRr.framer-1ri2jjr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 335px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 886px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rZDRr.framer-1ri2jjr { gap: 0px; } .framer-rZDRr.framer-1ri2jjr > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rZDRr.framer-1ri2jjr > :first-child { margin-left: 0px; } .framer-rZDRr.framer-1ri2jjr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 335
 * @framerIntrinsicWidth 886
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTZzvh8gxY: React.ComponentType<Props> = withCSS(Component, css, "framer-rZDRr") as typeof Component;
export default FramerTZzvh8gxY;

FramerTZzvh8gxY.displayName = "About/image with paralax";

FramerTZzvh8gxY.defaultProps = {height: 335, width: 886};

addFonts(FramerTZzvh8gxY, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})